import { Container } from "@mui/material";
import Header from "@components/Header";
import ImprintMain from "@components/ImprintMain";

const classes = {
  root: {
    paddingTop: "12px",
    paddingBottom: "33px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};

const ImprintPage = () => {
  return (
    <Container sx={classes.root} maxWidth={"xl"}>
      <Header />
      <ImprintMain />
    </Container>
  );
};

export default ImprintPage;
