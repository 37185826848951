import { Box, Typography } from "@mui/material";
import theme from "@styles/theme";
import { black, main } from "@styles/colors";
import { ReactComponent as Logo } from "@assets/black_logo.svg";

const classes = {
  root: {
    width: "100%",
    marginTop: "160px",
    display: "flex",
    gap: "16px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "72px",
    },
  },
  titleContainer: {
    width: "427px",
    [theme.breakpoints.down("md")]: {
      width: "auto",
      textAlign: "center",
    },
  },
  easy: {
    fontFamily: "TT Travels V",
    fontSize: "64px",
    lineHeight: "110%",
    fontWeight: 900,
    fontStyle: "italic",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      display: "contents",
    },
  },
  title: {
    fontFamily: "TT Travels V",
    fontSize: "64px",
    fontWeight: 350,
    lineHeight: "110%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      display: "contents",
    },
  },
  cardsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    width: "100%",
  },
  card: {
    width: "100%",
    padding: "32px",
    backgroundColor: "white",
    borderRadius: theme.spacing(0, 6, 6, 6),
    borderWidth: "3px",
    borderStyle: "solid",
    borderColor: black,
  },
  cardText: {
    fontSize: "20px",
    fontWeight: 500,
    fontStyle: "italic",
    lineHeight: "150%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  bottomCard: {
    width: "100%",
    padding: "32px",
    backgroundColor: main,
    borderRadius: theme.spacing(6, 0, 6, 6),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderWidth: "3px",
    borderStyle: "solid",
    borderColor: black,
  },
  bottomCardText: {
    fontSize: "24px",
    fontWeight: 700,
    fontStyle: "italic",
    lineHeight: "110%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
};

const Description = () => {
  return (
    <Box sx={classes.root}>
      <Box sx={classes.titleContainer}>
        <Typography sx={classes.title}>Market making makes markets </Typography>
        <Typography sx={classes.easy}>easy, for everyone</Typography>
      </Box>
      <Box sx={classes.cardsContainer}>
        <Box sx={classes.card}>
          <Typography sx={classes.cardText}>
            Bitcoin utility has expanded like never before. Fungible tokens
            (Runes) and NFTs (Ordinals) are now native to the chain. Sidechains,
            rollups, smart contract platforms, L2 chains and AMMs are all being
            constructed to help nurture Bitcoin out of its crib and establish it
            as the foundational unit of currency for global financial activity.
          </Typography>
        </Box>
        <Box sx={classes.card}>
          <Typography sx={classes.cardText}>
            These markets are ripe with brilliance, but short on liquidity. High
            barriers to access, ineffective trading markets, and high volatility
            make sound routes for investors and traders difficult.{" "}
          </Typography>
        </Box>
        <Box sx={classes.bottomCard}>
          <Logo />
          <Typography sx={classes.bottomCardText}>
            Let’s change that.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Description;
