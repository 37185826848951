import React from "react";
import LandingPage from "@pages/LandingPage";
import { Route, Routes } from "react-router-dom";
import ImprintPage from "@pages/ImprintPage";

const App = () => {
  return (
    <Routes>
      <Route path={"/imprint"} element={<ImprintPage />} />
      <Route path={"/*"} element={<LandingPage />} />
    </Routes>
  );
};

export default App;
