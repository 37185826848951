import React from "react";
import ReactDOM from "react-dom/client";
import theme from "@styles/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import App from "@src/App";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ThemeProvider>,
);
