import { Box, Typography, useMediaQuery } from "@mui/material";
import { ReactComponent as Card1 } from "@assets/card1.svg";
import { ReactComponent as Card2 } from "@assets/card2.svg";
import { ReactComponent as Card3 } from "@assets/card3.svg";
import { ReactComponent as Card4 } from "@assets/card4.svg";
import { ReactComponent as Card5 } from "@assets/card5.svg";
import { ReactComponent as Card6 } from "@assets/card6.svg";
import wave15 from "@assets/wave15.png";
import wave26 from "@assets/wave26.png";
import wave34 from "@assets/wave34.png";
import theme from "@styles/theme";
import { black } from "@styles/colors";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";

const classes = {
  root: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridTemplateRows: "repeat(2, 1fr)",
    columnGap: "16px",
    rowGap: "24px",
    marginTop: "48px",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "24px",
    },
  },
  card: {
    width: "100%",
    height: "388px",
    display: "flex",
    position: "relative",
    flexDirection: "column",
    padding: theme.spacing(5, 4, 3, 4),
    justifyContent: "space-between",
    backgroundColor: "white",
    borderWidth: "3px",
    borderStyle: "solid",
    borderColor: black,
    borderRadius: theme.spacing(0, 6, 6, 6),
    [theme.breakpoints.down("sm")]: {
      width: "300px",
      marginRight: "10px",
      marginLeft: "10px",
    },
  },
  cardText: {
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "120%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  logoContainer: {
    zIndex: 1,
  },
  cardBg: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    borderRadius: theme.spacing(0, 0, 5.5, 5.5),
  },
};

const cards: {
  text: string;
  logo: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  bg: string;
}[] = [
  {
    text: "Launch liquidity for vetted projects",
    logo: Card1,
    bg: wave15,
  },
  {
    text: "Best-in-class spreads for traders and institutions",
    logo: Card2,
    bg: wave26,
  },
  {
    text: "Broad inventories of top class projects",
    logo: Card3,
    bg: wave34,
  },
  {
    text: "Secure entries and exits for retail investors",
    logo: Card4,
    bg: wave34,
  },
  {
    text: "Price discovery and efficient markets for Bitcoin innovators",
    logo: Card5,
    bg: wave15,
  },
  {
    text: "A stable liquidity platform for the future of Bitcoin liquidity",
    logo: Card6,
    bg: wave26,
  },
];

const Cards = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={classes.root}>
      {isMobile ? (
        <ScrollMenu>
          {cards.map((item, index) => (
            <Box key={index} sx={classes.card}>
              <Typography sx={classes.cardText}>{item.text}</Typography>
              <Box sx={classes.logoContainer}>{<item.logo />}</Box>
              <Box
                sx={classes.cardBg}
                component={"img"}
                src={item.bg}
                alt={""}
              />
            </Box>
          ))}
        </ScrollMenu>
      ) : (
        cards.map((item, index) => (
          <Box key={index} sx={classes.card}>
            <Typography sx={classes.cardText}>{item.text}</Typography>
            <Box sx={classes.logoContainer}>{<item.logo />}</Box>
            <Box sx={classes.cardBg} component={"img"} src={item.bg} alt={""} />
          </Box>
        ))
      )}
    </Box>
  );
};

export default Cards;
