import { Components, Theme } from "@mui/material";
import { black, main, peach } from "@styles/colors";

const getOverridesComponents = (theme: Theme): Components => ({
  MuiCssBaseline: {
    styleOverrides: `
    @font-face {
      font-family: 'TT Travels V';
      font-style: normal;
      font-weight: 350;
      src: url(./fonts/TT_Travels_Next_Trial_Variable.ttf) format('truetype');
    }
    @font-face {
      font-family: 'TT Travels V';
      font-style: normal;
      font-weight: 700;
      src: url(./fonts/TT_Travels_Next_Trial_Variable.ttf) format('truetype');
    }
    @font-face {
      font-family: 'TT Travels V';
      font-style: italic;
      font-weight: 900;
      src: url(./fonts/TT_Travels_Next_Trial_Variable.ttf) format('truetype');
    }
    @font-face {
      font-family: 'TT Travels V';
      font-style: italic;
      font-weight: 600;
      src: url(./fonts/TT_Travels_Next_Trial_Variable.ttf) format('truetype');
    }
    @font-face {
      font-family: 'TT Travels';
      font-style: normal;
      font-weight: 400;
      src: url(./fonts/TT_Travels_Trial_Regular.otf) format('opentype');
    }
    @font-face {
      font-family: 'TT Travels';
      font-style: normal;
      font-weight: 500;
      src: url(./fonts/TT_Travels_Trial_Medium.otf) format('opentype');
    }
    @font-face {
      font-family: 'TT Travels';
      font-style: normal;
      font-weight: 700;
      src: url(./fonts/TT_Travels_Trial_Bold.otf) format('opentype');
    }
    @font-face {
      font-family: 'TT Travels';
      font-style: normal;
      font-weight: 800;
      src: url(./fonts/TT_Travels_Trial_Black.otf) format('opentype');
    }
    @font-face {
      font-family: 'TT Travels';
      font-style: italic;
      font-weight: 400;
      src: url(./fonts/TT_Travels_Trial_Regular_Italic.otf) format('opentype');
    }
    @font-face {
      font-family: 'TT Travels';
      font-style: italic;
      font-weight: 500;
      src: url(./fonts/TT_Travels_Trial_Medium_Italic.otf) format('opentype');
    }
    @font-face {
      font-family: 'TT Travels';
      font-style: italic;
      font-weight: 700;
      src: url(./fonts/TT_Travels_Trial_Bold_Italic.otf) format('opentype');
    }
    @font-face {
      font-family: 'TT Travels';
      font-style: italic;
      font-weight: 800;
      src: url(./fonts/TT_Travels_Trial_Black_Italic.otf) format('opentype');
    }
    body {
      font-feature-settings: 'ss01' on, 'salt' on;
    }
  `,
  },
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: "none",
      },
      contained: {
        backgroundColor: main,
        color: black,
        padding: theme.spacing(2, 8),
        borderRadius: 200,
        "&:hover": {
          backgroundColor: main,
          color: black,
        },
      },
      text: {
        color: peach,
        "&:hover": {
          color: main,
        },
      },
    },
  },
});

export default getOverridesComponents;
