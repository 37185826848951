import { Box, Button } from "@mui/material";
import logo from "@assets/logo.png";
import { black } from "@styles/colors";
import theme from "@styles/theme";
import { Link } from "react-router-dom";

const classes = {
  root: {
    width: "100%",
    height: "96px",
    padding: "16px",
    borderRadius: "48px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: black,
    [theme.breakpoints.down("sm")]: {
      height: "80px",
    },
  },
  imgContainer: {
    height: "100%",
  },
  img: {
    height: "100%",
  },
  ctaButton: {
    fontFamily: "TT Travels V",
    fontWeight: 700,
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
};

const Header = () => {
  return (
    <Box sx={classes.root}>
      <Box sx={classes.imgContainer}>
        <Link to={"/"}>
          <Box sx={classes.img} component={"img"} src={logo} alt={""} />
        </Link>
      </Box>
      <Box>
        <Link to={"mailto:contact@bitpool.xyz"}>
          <Button sx={classes.ctaButton} variant={"contained"}>
            Contact
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default Header;
