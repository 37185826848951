import { Box, Typography } from "@mui/material";
import { black } from "@styles/colors";
import theme from "@styles/theme";

const classes = {
  root: {
    width: "100%",
    marginTop: "40px",
    display: "flex",
    flexWrap: "wrap",
    rowGap: "24px",
    columnGap: "8px",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      rowGap: "8px",
      columnGap: "4px",
      marginTop: "24px",
      justifyContent: "start",
    },
  },
  chip: {
    borderWidth: "3px",
    borderRadius: "200px",
    borderStyle: "solid",
    borderColor: black,
    padding: theme.spacing(1.625, 3.625),
    backgroundColor: "white",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0.625, 1.125),
    },
  },
  text: {
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "150%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
};

const chipsArr: string[] = [
  "Runes",
  "Ordinals",
  "Bitcoin L1s and L2s",
  "AMMs and DEX",
  "Sovereign Rollups",
  "Smart Contract Platforms",
  "Tokenized RWAs",
  "Sidechains",
  "Stablecoins",
  "Memes and Metaverses",
];

const Chips = () => {
  return (
    <Box sx={classes.root}>
      {chipsArr.map((item, index) => (
        <Box key={index} sx={classes.chip}>
          <Typography sx={classes.text}>{item}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default Chips;
