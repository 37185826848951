import { Box, Typography } from "@mui/material";
import React from "react";
import theme from "@styles/theme";
import { Link } from "react-router-dom";

const classes = {
  root: {
    marginTop: "26px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "12px",
    },
  },
  typo: {
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "150%",
    fontStyle: "italic",
    textAlign: "center",
  },
};

const Copy = () => {
  return (
    <Box sx={classes.root}>
      <Typography sx={classes.typo}>
        <Link to={"/imprint"}>Imprint</Link> . Copyright © 2024 bitpool
      </Typography>
    </Box>
  );
};

export default Copy;
