import Header from "@components/Header";
import { Container } from "@mui/material";
import Main from "@components/Main";
import Description from "@components/Description";
import CardsTitle from "@components/CardsTitle";
import Cards from "@components/Cards";
import Chips from "@components/Chips";
import Footer from "@components/Footer";
import Copy from "@components/Copy";

const classes = {
  root: {
    paddingTop: "12px",
    paddingBottom: "33px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};

const LandingPage = () => {
  return (
    <Container sx={classes.root} maxWidth={"xl"}>
      <Header />
      <Main />
      <Description />
      <CardsTitle />
      <Cards />
      <Chips />
      <Footer />
      <Copy />
    </Container>
  );
};

export default LandingPage;
