import { Box, Typography } from "@mui/material";
import { black } from "@styles/colors";
import bg from "@assets/bg_bottom.png";
import theme from "@styles/theme";

const classes = {
  root: {
    width: "100%",
    height: "288px",
    marginTop: "160px",
    marginBottom: "89px",
    borderWidth: "3px",
    borderColor: black,
    borderRadius: theme.spacing(6),
    borderStyle: "solid",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginTop: "72px",
    },
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: theme.spacing(5.5),
  },
  textContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      top: "32px",
      transform: "translate(-50%, -0%)",
    },
  },
  title: {
    fontWeight: 700,
    fontSize: "64px",
    fontFamily: "TT Travels V",
    lineHeight: "110%",
    [theme.breakpoints.down("md")]: {
      fontSize: "36px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
  },
};

const Footer = () => {
  return (
    <Box sx={classes.root}>
      <Box sx={classes.textContainer}>
        <Typography sx={classes.title}>Fueling Bitcoin's</Typography>
        <Typography sx={classes.title}>Liquidity Revolution</Typography>
      </Box>
      <Box sx={classes.img} component={"img"} src={bg} alt={""} />
    </Box>
  );
};

export default Footer;
