import { Box, Typography } from "@mui/material";
import theme from "@styles/theme";
import bg from "@assets/bg.png";
import { black } from "@styles/colors";
import logo from "@assets/logo_black.png";

const classes = {
  root: {
    height: "722px",
    width: "100%",
    marginTop: "24px",
    padding: theme.spacing(6),
    backgroundImage: `url(${bg})`,
    borderRadius: "48px",
    borderWidth: "3px",
    borderStyle: "solid",
    borderColor: black,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      marginTop: "12px",
      height: "598px",
    },
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "24px",
    [theme.breakpoints.down("sm")]: {
      gap: "20px",
    },
  },
  title: {
    fontSize: "64px",
    fontWeight: 700,
    textAlign: "center",
    lineHeight: "110%",
    fontFamily: "TT Travels V",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
  },
  subTitle: {
    fontSize: "24px",
    fontWeight: 500,
    fontStyle: "italic",
    textAlign: "center",
    lineHeight: "110%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  imgContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  img: {
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },
  },
};

const Main = () => {
  return (
    <Box sx={classes.root}>
      <Box sx={classes.titleContainer}>
        <Typography sx={classes.title}>
          A Market Maker for the Bitcoin Ecosystem
        </Typography>
        <Typography sx={classes.subTitle}>
          On-chain liquidity reservoir for the rivers, streams and torrents of
          Bitcoin innovation
        </Typography>
      </Box>
      <Box sx={classes.imgContainer}>
        <Box sx={classes.img} component={"img"} src={logo} alt={""} />
      </Box>
    </Box>
  );
};

export default Main;
