import { Box, Typography } from "@mui/material";
import { ReactComponent as Dip } from "@assets/dip.svg";
import theme from "@styles/theme";
import { black } from "@styles/colors";

const classes = {
  root: {
    width: "100%",
    marginTop: "160px",
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "72px",
      gap: "24px",
    },
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "16px",
    [theme.breakpoints.down("xl")]: {
      flexWrap: "wrap",
    },
  },
  title: {
    fontFamily: "TT Travels V",
    fontSize: "64px",
    fontWeight: 700,
    lineHeight: "110%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
  },
  iconContainer: {
    width: "70px",
    height: "70px",
    [theme.breakpoints.down("sm")]: {
      width: "24px",
      height: "24px",
    },
  },
  weProvide: {
    padding: theme.spacing(2, 6),
    borderWidth: "3px",
    borderRadius: "200px",
    borderStyle: "solid",
    borderColor: black,
  },
  weProvideText: {
    fontSize: "24px",
    fontWeight: 600,
    fontStyle: "italic",
    fontFamily: "TT Travels V",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
};

const CardsTitle = () => {
  return (
    <Box sx={classes.root}>
      <Box sx={classes.titleContainer}>
        <Typography sx={classes.title}>Take a dip</Typography>
        <Box sx={classes.iconContainer}>
          <Dip width={"100%"} height={"100%"} />
        </Box>
        <Typography sx={classes.title}>in the Bitpool</Typography>
      </Box>
      <Box sx={classes.weProvide}>
        <Typography sx={classes.weProvideText}>We provide</Typography>
      </Box>
    </Box>
  );
};

export default CardsTitle;
