import { createTheme, Theme } from "@mui/material";
import getOverridesComponents from "@styles/getOverridesComponents";
import getTypography from "@styles/getTypography";
import { peach } from "@styles/colors";

const createCustomTheme = (): Theme => {
  const baseTheme = createTheme({
    spacing: (x: number) => `${x * 8}px`,
    palette: {
      background: {
        default: peach,
      },
      text: {
        primary: "#000",
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1024,
        xl: 1365,
      },
    },
  });

  return createTheme({
    ...baseTheme,
    components: getOverridesComponents(baseTheme),
    typography: getTypography(baseTheme),
  });
};

export default createCustomTheme();
